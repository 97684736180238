.gridStyle {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(132px, 1fr));
  column-gap: 12px;
  row-gap: 12px;
  grid-auto-flow: row dense;
  grid-auto-rows: 64px;
}

.grid-item11 {
  grid-column-start: auto;
  grid-column-end: span 1;
  grid-row-start: auto;
  grid-row-end: span 1;
}

.grid-item12 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 1;
}

.grid-item13 {
  grid-column-start: auto;
  grid-column-end: span 1;
  grid-row-start: auto;
  grid-row-end: span 3;
}

.grid-item14 {
  grid-column-start: auto;
  grid-column-end: span 1;
  grid-row-start: auto;
  grid-row-end: span 4;
}

.grid-item21 {
  grid-column-start: auto;
  grid-column-end: span 1;
  grid-row-start: auto;
  grid-row-end: span 2;
}

.grid-item22 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 2;
}

.grid-item23 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 3;
}

.grid-item24 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 4;
}

.grid-item25 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 5;
}

.grid-item26 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 6;
}

.grid-item13 {
  grid-column-start: auto;
  grid-column-end: span 1;
  grid-row-start: auto;
  grid-row-end: span 3;
}

.grid-item23 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 3;
}

.grid-item33 {
  grid-column-start: auto;
  grid-column-end: span 3;
  grid-row-start: auto;
  grid-row-end: span 3;
}

.grid-item43 {
  grid-column-start: auto;
  grid-column-end: span 4;
  grid-row-start: auto;
  grid-row-end: span 3;
}

.grid-item14 {
  grid-column-start: auto;
  grid-column-end: span 1;
  grid-row-start: auto;
  grid-row-end: span 4;
}

.grid-item24 {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: span 4;
}

.grid-item34 {
  grid-column-start: auto;
  grid-column-end: span 3;
  grid-row-start: auto;
  grid-row-end: span 4;
}

.grid-item44 {
  grid-column-start: auto;
  grid-column-end: span 4;
  grid-row-start: auto;
  grid-row-end: span 4;
}

.gridArea {
  margin: 0px 12%;
  padding: 24px;
  z-index: 3;
}

.logo {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2px;
  left: 16px;
  z-index: 10;
}

.logo_img {
  width: 76px !important;
  height: 76px !important;
  border-radius: 4px;
}

.settings {
  display: flex;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 14px;
  height: 32px;
  position: fixed;
  z-index: 10;
}

.settings_option {
  display: flex;
  margin: 1rem;
  width: 100vw;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.settings_option p {
  color: #ffffff;
  z-index: 1;
  margin-bottom: 0px;
  font-weight: bold;
}

.input-div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.input-div input {
  width: calc(100%);
}

.edit-show-div {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.delete-button-style {
  position: absolute;
  z-index: 100;
}

.delete-button-style svg {
  width: 20px;
  height: 20px;
}

.edit-button-style {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  padding: 4px;
  border-radius: 5px;
}

.edit-button-style svg {
  width: 20px;
  height: 20px;
}

.search-input-style {
  display: flex;
  width: 50%;
  border-radius: 50px;
  max-width: 600px;
  min-width: 320px;
  justify-content: center;
}

.searchButton svg {
  width: 22px;
  height: 22px;
}

.dock {
  text-align: center;
  background: #ffffff;
}

.components-card-style {
  background: rgb(245, 245, 245);
  position: relative;
  overflow: hidden;
}

.components-card-style svg {
  width: 30px;
  height: 30px;
  margin-top: 4.25px;
}

.components-card-style img {
  height: 126px;
  transition: all 0.2s;
}

.maskStyle {
  border-radius: 6px;
}

.ant-popconfirm-message-title {
  width: 200px;
}

.time-Process-100 {
  justify-content: center;
}

.time-Process-100 svg {
  height: 40px;
  width: 40px;
  color: green;
}

.time-Process-0 {
  justify-content: center;
}

.time-Process-0 svg {
  height: 33px;
  width: 33px;
  color: rgb(110, 110, 110);
}

.search-engine-space {
  margin-right: -66px;
  z-index: 99999;
  height: 46px !important;
}

.search-engine-space img {
  height: 22px !important;
  width: 22px !important;
  margin-top: 8px !important;
}

@media screen and (max-width: 480px) {
  .gridArea {
    padding: 24px 12px;
    z-index: 3;
    margin: 0px;
  }
}

@media screen and (max-width: 720px) {
  .settings {
    display: flex;
    align-items: center;
    position: absolute;
    top: 14px;
    right: 14px;
    height: 32px;
    position: none;
  }
}
