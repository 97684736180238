.clock {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: Roboto, arial, sans-serif !important;
}

.clock-card {
    width: auto;
    height: auto;
    background: none;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.clock-card p {
    margin-bottom: 0px;
    font-weight: bold;
}

.hourclass {
    line-height: 120px;
    font-size: 120px;
    color: white;
    margin: 0px;
}

.hourclass span {
    font-size: '120px'
}

.secondsclass {
    font-size: 60px;
    color: white;
    margin-top: 18px;
    margin-left: 8px;
}

.dateclass {
    width: 100%;
    color: white;
    text-align: center;
}

.dateclass p {
    margin-bottom: 0px;
    font-size: 35px;
    font-weight: bold;
}

@media screen and (max-width:650px) {
    .hourclass {
        line-height: 80px;
        font-size: 80px;
    }

    .hourclass span {
        font-size: '80px'
    }

    .dateclass p {
        display: none;
    }
}